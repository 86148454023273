import '../App.css';
import logo from '../img/im-logo.svg';
import useForm from '../useForm';
import {FormControl, Input, Link} from '@chakra-ui/react'
import axios from 'axios';
import React from "react";

const LoginScreen = (props) => {
  axios.defaults.withCredentials = true;
  const formLogin = () => {};

  const { handleChange, values, errors, handleSubmit } = useForm(formLogin);
  return (
    <div>
      <div className="login-background">
        <div className="login-container">
          <div className="rounded-rectangle">
            <div className="logo-text">
              <img className="im-logo" src={logo} alt={'IM LOGO'} />
              <h2>Login</h2>
              <h4>Enter your credentials</h4>
              <form className="login-in-form" onSubmit={handleSubmit}>
                <FormControl padding={'2%'} isRequired>
                  <Input
                      padding={'2%'}
                      type="email"
                      id="email"
                      name={'Email'}
                      placeholder="Email"
                      borderStyle={'double'}
                      borderColor={'black'}
                      backgroundColor={'white'}
                      onChange={handleChange}
                  />
                </FormControl>
                {errors.email && <h4 className="error-email">{errors.email}</h4>}
                <br />
                <FormControl padding={'2%'} >
                  <Input
                      type="password"
                      name="Password"
                      placeholder="Password"
                      borderStyle={'double'}
                      borderColor={'black'}
                      backgroundColor={'white'}
                      onChange={handleChange}
                  />
                </FormControl>
                {errors.password && <h4 className="error-password">{errors.password}</h4>}
                <br />
                <button className="submit-button">Log In</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginScreen;
