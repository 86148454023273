import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import StateContext from "../StateContext";
import ContactInfoBox from "../components/ContactInfoBox";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  Text,
  Grid,
  VStack,
  useColorModeValue,
  Wrap,
} from "@chakra-ui/react";
import publicIp from "public-ip";
const baseUrl = process.env.REACT_APP_BASEURL;

const ContactsDashboard = () => {
  axios.defaults.withCredentials = true;
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [queryCount, setQueryCount] = useState(0);
  const [additionalParam, setAdditionalParam] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const [date, setDate] = useState(new Date());
  const appState = useContext(StateContext);

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "limit") {
      value = parseInt(value);
    }

    console.log(name, value);
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleAdditionalParam = (event) => {
    console.log(event);
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case "first_name":
        additionalParam.first_name = value;
        break;
      case "middle_name":
        additionalParam.middle_name = value;
        break;
      case "last_name":
        additionalParam.last_name = value;
        break;
      case "gender":
        additionalParam.gender = value;
        break;
      case "ethnicity":
        additionalParam.ethnicity = value;
        break;
      case "age":
        additionalParam.age = value;
        break;
      case "email":
        additionalParam.email = value;
        break;
      case "phone":
        additionalParam.phone = value;
        break;
      case "birthdate":
        additionalParam.birthdate = value;
        break;
      case "address.street":
        additionalParam["address.street"] = value;
        break;
      case "address.street_opt":
        additionalParam["address.street_opt"] = value;
        break;
      case "address.city":
        additionalParam["address.city"] = value;
        break;
      case "address.country":
        additionalParam["address.country"] = value;
        break;
      case "address.zip":
        additionalParam["address.zip"] = value;
        break;
    }

    console.log(additionalParam);
    setAdditionalParam(additionalParam);
  };

  const handleBirthDate = (date) => {
    additionalParam["birthdate"] = date;
    setDate(date);
    setAdditionalParam(additionalParam);
  };

  async function handlePage(event) {
    let updatedChunkIndex = 0;
    const value = event.target.value;
    const limit = inputs.limit;

    switch (value) {
      case "previous":
        if (inputs.chunk_index > 0 && inputs.chunk_index - inputs.limit >= 0) {
          updatedChunkIndex = inputs.chunk_index - inputs.limit;
        }
        break;

      case "next":
        if (
          inputs.chunk_index >= 0 &&
          inputs.chunk_index + inputs.limit < queryCount
        ) {
          updatedChunkIndex = inputs.chunk_index + inputs.limit;
        } else if (inputs.chunk_index + inputs.limit > queryCount) {
          let modulus = inputs.chunk_index % limit;
          updatedChunkIndex = inputs.chunk_index - modulus;
        } else if (inputs.chunk_index >= queryCount) {
          console.log("At max");
        }
        break;

      case "first":
        updatedChunkIndex = 0;
        break;

      case "last":
        let modulus = queryCount % limit;
        updatedChunkIndex = queryCount - modulus;
        console.log(updatedChunkIndex);
        break;
    }
    setInputs((values) => ({ ...values, ["chunk_index"]: updatedChunkIndex }));
    queryContactsPageinated(updatedChunkIndex, limit);
  }

  async function queryContactsPageinated(index, limit) {
    try {
      const response = await axios.post(
        baseUrl + "/dashboard-contacts-search",
        {
          limit: limit,
          chunk_index: index,
          regex_query: inputs.regex_query,
          organization_id: inputs.organization || "all",
          sort: inputs.sort,
          additional_params: additionalParam,
        },
        {
          headers: {
            "x-access-token": appState.user.token,
          },
        },
        { withCredentials: true }
      );

      setIsLoading(false);
      console.log(response.data);
      setContacts(response.data.data);
      setQueryCount(response.data.info.query_count);
    } catch (e) {
      console.log("There was a problem");
    }
  }

  async function queryContacts() {
    try {
      const response = await axios.post(
        baseUrl + "/dashboard-contacts-search",
        {
          limit: inputs.limit,
          chunk_index: inputs.chunk_index,
          regex_query: inputs.regex_query,
          organization_id: inputs.organization || "all",
          sort: inputs.sort,
          additional_params: additionalParam,
        },
        {
          headers: {
            "x-access-token": appState.user.token,
          },
        },
        { withCredentials: true }
      );

      setIsLoading(false);
      console.log(response.data);
      setContacts(response.data.data);
      setQueryCount(response.data.info.query_count);
    } catch (e) {
      console.log("There was a problem");
    }
  }
  useEffect(() => {
    async function fetchOrganizations() {
      try {
        const organizationsResponse = await axios.get(
          baseUrl + "/get-agent-organizations",
          {
            headers: {
              "x-access-token": appState.user.token,
            },
          },
          { withCredentials: true }
        );

        console.log(organizationsResponse.data);
        setOrganizations(organizationsResponse.data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchOrganizations();
  }, []);

  useEffect(() => {
    async function fetchContacts() {
      try {
        const response = await axios.post(
          baseUrl + "/dashboard-contacts-search",
          {
            limit: 10,
            chunk_index: 0,
            regex_query: "",
            sort: "1",
            organization_id: inputs.organization || "all",
            additional_params: additionalParam,
          },
          {
            headers: {
              "x-access-token": appState.user.token,
            },
          },
          { withCredentials: true }
        );

        setIsLoading(false);
        setContacts(response.data.data);
        setQueryCount(response.data.info.query_count);

        let event = {
          target: {
            name: "regex_query",
            value: "",
          },
        };
        handleChange(event);

        event = {
          target: {
            name: "sort",
            value: "1",
          },
        };
        handleChange(event);

        event = {
          target: {
            name: "limit",
            value: 10,
          },
        };
        handleChange(event);

        event = {
          target: {
            name: "chunk_index",
            value: 0,
          },
        };
        handleChange(event);
      } catch (e) {
        console.log("There was a problem");
      }
    }
    fetchContacts();
  }, []);

  return (
    <div className={"background-crm"}>
      <Box margin={"1%"}>
        <VStack
          margin={"1%"}
          bg={useColorModeValue("white", "gray.900")}
          borderRadius={"6px"}
          align="stretch"
        >
          <HStack>
            <VStack
              margin={"2%"}
              align="stretch"
              fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            >
              <Text align={"left"}>Search </Text>
              <Input
                name={"regex_query"}
                onChange={handleChange}
                fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                placeHolder={"Keyword"}
              ></Input>
            </VStack>
            <VStack
              margin={"2%"}
              align="stretch"
              fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            >
              <Text align={"left"}>Sort</Text>
              <Select
                name={"sort"}
                onChange={(e) => handleChange(e)}
                fontSize={{ base: "12px", md: "14px", lg: "16px" }}
              >
                <option value={"1"}>Oldest First</option>
                <option value={"2"}>Newest First</option>
                <option value={"3"}>Alphabetic Order First Name</option>
                <option value={"4"}>Alphabetic Reverse First Name</option>
                <option value={"5"}>Alphabetic Order Last Name</option>
                <option value={"6"}>Alphabetic Reverse Order Last Name</option>
              </Select>
            </VStack>
            <VStack
              margin={"2%"}
              align="stretch"
              fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            >
              <Text align={"left"}>Properties</Text>
              <Select
                onChange={handleChange}
                defaultValue={"all"}
                name={"organization"}
                width={"200px"}
              >
                <option value={"all"}>All Properties</option>
                {organizations.map((organization) => {
                  return (
                    <option value={organization.organization_id}>
                      {organization.organization_name}
                    </option>
                  );
                })}
              </Select>
            </VStack>
            <VStack
              margin={"2%"}
              align="stretch"
              fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            >
              <Text align={"left"}>No. </Text>
              <Select
                name={"limit"}
                onChange={(e) => handleChange(e)}
                fontSize={{ base: "12px", md: "14px", lg: "16px" }}
              >
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
                <option value={10000}>10000</option>
              </Select>
            </VStack>
            <Button
              onClick={queryContacts}
              fontSize={{ base: "12px", md: "14px", lg: "16px" }}
            >
              Search
            </Button>
          </HStack>
          <Accordion allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton
                  fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                >
                  <Box flex="1" textAlign="left">
                    Extended Search
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={10}
                fontSize={{ base: "12px", md: "14px", lg: "16px" }}
              >
                <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2, 3]} align={"left"}>
                        First Name
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        name={"first_name"}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"First Name"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        Middle Name
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        name={"middle_name"}
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"Middle Name"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        Last Name
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        name={"last_name"}
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"Last Name"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        Gender
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        name={"gender"}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"Gender"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        Ethnicity
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        name={"ethnicity"}
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"Ethnicity"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        Age
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        name={"age"}
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"Age"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        Email
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        name={"email"}
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"Email"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        Phone
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        name={"phone"}
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"Phone"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        Street
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        name={"address.street"}
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"Street"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        Street Opt
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        name={"address.street_opt"}
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"Street Opt"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        City
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        name={"address.city"}
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"City"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        State
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        name={"address.state"}
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"State"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        Country
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        name={"address.country"}
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"Country"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        Zip
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Input
                        name={"address.zip"}
                        fontSize={{ base: "12px", md: "14px", lg: "16px" }}
                        onChange={(e) => handleAdditionalParam(e)}
                        placeHolder={"Zip"}
                      ></Input>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box>
                      <Text noOfLines={[1, 2]} align={"left"}>
                        Birth Date
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      {/*
                                            <DatePicker
                                                selected={date}
                                                onChange={(event) => handleBirthDate(event)}
                                                dateFormat={'yyyy-MM-dd'}
                                            />
                                            */}
                    </Box>
                  </Flex>
                </Grid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </VStack>
        <Spacer />
        <Link to={"/add-customer"}>
          <Button
            mr={"4"}
            margin={"1%"}
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
          >
            + Add Contact
          </Button>
        </Link>
        <HStack margin={"1%"}>
          <Button
            onClick={(e) => handlePage(e)}
            name={"chunk_index"}
            value={"first"}
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
          >
            First
          </Button>
          <Button
            onClick={(e) => handlePage(e)}
            name={"chunk_index"}
            value={"previous"}
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
          >
            Previous
          </Button>
          <Button
            onClick={(e) => handlePage(e)}
            name={"chunk_index"}
            value={"next"}
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
          >
            Next
          </Button>
          <Button
            onClick={(e) => handlePage(e)}
            name={"chunk_index"}
            value={"last"}
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
          >
            Last
          </Button>
          <Text color={"white"}>
            {inputs.chunk_index + 1}-{inputs.limit + inputs.chunk_index}:{" "}
            {queryCount}
          </Text>
        </HStack>
        {contacts.map((contact) => {
          return (
            <Grid
              key={contact._id}
              overflow={"hidden"}
              margin={"1%"}
              bg={"white"}
              gap={2}
              padding={"1%"}
              paddingLeft={"2%"}
              borderRadius={"6px"}
            >
              <Accordion allowToggle allowMultiple w={"100%"}>
                <AccordionItem w={"100%"}>
                  <AccordionButton w={"100%"}>
                      <Box>
                        <Text overflow={"hidden"} fontSize={"lg"}>
                          <b>{contact.first_name} {contact.middle_name} {contact.last_name} </b> {contact.phone}
                        </Text>
                      </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <ContactInfoBox contact={contact}/>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Grid>
          );
        })}
        <HStack margin={"1%"}>
          <Button
            onClick={(e) => handlePage(e)}
            name={"chunk_index"}
            value={"first"}
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
          >
            First
          </Button>
          <Button
            onClick={(e) => handlePage(e)}
            name={"chunk_index"}
            value={"previous"}
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
          >
            Previous
          </Button>
          <Button
            onClick={(e) => handlePage(e)}
            name={"chunk_index"}
            value={"next"}
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
          >
            Next
          </Button>
          <Button
            onClick={(e) => handlePage(e)}
            name={"chunk_index"}
            value={"last"}
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
          >
            Last
          </Button>
          <Text color={"white"}>
            {inputs.chunk_index + 1}-{inputs.limit + inputs.chunk_index}:{" "}
            {queryCount}
          </Text>
        </HStack>
      </Box>
    </div>
  );
};

export default ContactsDashboard;
