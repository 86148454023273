import React, { useContext, useEffect, useState } from "react";
import { Badge, Box, Heading, Text } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import StateContext from "../StateContext";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

export default function ActivityLog(props) {
  const [inputs, setInputs] = useState({});
  const [sort, setSort] = useState("0");
  const [commLog, setCommLog] = useState([]);
  const appState = useContext(StateContext);

  useEffect(() => {
    async function fetchActivityLog() {
      try {
        const commLogResponse = await axios.post(
          baseUrl + "/get-comm-log-activity-log",
          {
            phone: props.phone,
          },
          {
            headers: {
              "x-access-token": appState.user.token,
              "Content-Type": "application/json",
            },
          },
          { withCredentials: true }
        );
        console.log(commLogResponse.data);
        setCommLog(commLogResponse.data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchActivityLog();
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <Box
      padding="2%"
      margin="2%"
      bg="whitesmoke"
      borderRadius={"4px"}
      boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
      w={"300px"}
    >
      <Heading align={"center"} size={"md"}>
        Activity Log
      </Heading>
      <Box h={"450px"} overflow={"scroll"}>
        {commLog.map((comm_log) => {
          return (
            <>
              {comm_log.is_incoming === "1" ? (
                <>
                  {comm_log.is_sms === "1" ? (
                    <Box
                      bg={"#E8E8E8"}
                      borderRadius={"8px"}
                      padding="2%"
                      margin="2%"
                      key={comm_log._id}
                      float={"left"}
                      w={"200px"}
                    >
                      <Badge>SMS</Badge>
                      <Text>{comm_log.report}</Text>
                      <Text>
                        <b>{new Date(comm_log.createdAt).toLocaleString()}</b>
                      </Text>
                    </Box>
                  ) : (
                    <Box
                      bg={"#E8E8E8"}
                      borderRadius={"8px"}
                      padding="2%"
                      margin="2%"
                      key={comm_log._id}
                      float={"left"}
                      w={"200px"}
                    >
                      {comm_log.is_missed_call === "1" ? (
                        <Badge colorScheme="red">Missed Call</Badge>
                      ) : (
                        <>
                          <Badge>Call</Badge>
                          <Text>Duration: {comm_log.duration} seconds</Text>
                        </>
                      )}
                      <Text>
                        <b>{new Date(comm_log.createdAt).toLocaleString()}</b>
                      </Text>
                    </Box>
                  )}
                  <br />
                </>
              ) : (
                <>
                  {comm_log.is_sms === "1" ? (
                    <Box
                      bg={"#2671ff"}
                      borderRadius={"8px"}
                      padding="2%"
                      margin="2%"
                      key={comm_log._id}
                      float={"right"}
                      w={"200px"}
                    >
                      <Badge>SMS</Badge>
                      <Text color={"rgba(255,255,255,.7)"}>
                        {comm_log.report}
                      </Text>
                      <Text color={"rgba(255,255,255,.7)"}>
                        <b>{new Date(comm_log.createdAt).toLocaleString()}</b>
                      </Text>
                    </Box>
                  ) : (
                    <Box
                      bg={"#2671ff"}
                      borderRadius={"8px"}
                      padding="2%"
                      margin="2%"
                      key={comm_log._id}
                      float={"right"}
                      w={"200px"}
                    >
                      {comm_log.is_missed_call === "1" ? (
                        <Badge colorScheme="red">Missed Call</Badge>
                      ) : (
                        <Badge>Call</Badge>
                      )}
                      <Text color={"rgba(255,255,255,.7)"}>
                        <b>{new Date(comm_log.createdAt).toLocaleString()}</b>
                      </Text>
                    </Box>
                  )}
                  <br />
                </>
              )}
              <br />
            </>
          );
        })}
        <br />
      </Box>
    </Box>
  );
}
