import { Outlet } from 'react-router';
import StateContext from './StateContext';
import { useContext } from 'react';

const useAuth = () => {
  const appState = useContext(StateContext); //Add axios request to get role of user
  return appState.user.role;
}

const AdminRoutes = () => {
  const isAuth = useAuth();

  if (isAuth == 'admin') {
    return ( <Outlet /> )
  } else {
    return (
      <div>
        {' '}
        <b>Restricted Access</b>{' '}
      </div>
    )
  }
}

export default AdminRoutes;
