import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import StateContext from "../StateContext";
import {
  Badge,
  Box,
  Checkbox,
  Heading,
  Select,
  Text,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import DateTimePicker from "react-datetime-picker";
import CommLogTable from "../components/CommLogTable";
import { DownloadReportComponent } from "../components/DownloadReport";
import {
  CallStatBox,
  DonutGraphMissedCalls,
  SMSStatBox,
  LineGraphSMSLog,
  BarGraphCallLog,
  BarGraphSMSLog,
  RecentSMSViewer,
  RecentCallViewer,
  LineGraphCallLog,
} from "../components/Graphs";
import { DownloadStatReportComponent } from "../components/DownloadStatReport";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import ContactInfoBoxModal from "../components/ContactInfoBoxModal";
import ContactFormModal from "../components/ContactFormModal";
import ActivityLog from "../components/ActivityLog";
const baseUrl = process.env.REACT_APP_BASEURL;

const Dashboard = () => {
  axios.defaults.withCredentials = true;
  const toast = useToast();
  const [signal, setSignal] = useState();
  const [timeInterval, setTimeInterval] = useState(1)
  const [earliestDate, setEarliestDate] = useState(new Date());
  const [latestDate, setLatestDate] = useState(new Date());

  const [liveData, setLiveData] = useState("start");
  const [liveDataBool, setLiveDataBool] = useState(true);
  const [commLogStat, setCommLogStat] = useState({
    total_num_calls: 0,
    total_answered_calls: 0,
    total_missed_calls: 0,
    total_sms: 0,
    total_sms_outgoing: 0,
    total_sms_incoming: 0,
    total_calls_outgoing: 0,
    total_calls_incoming: 0,
    total_outgoing: 0,
    total_incoming: 0,
    missed_call_percentage: 0,
    answered_call_percentage: 0,
    average_duration_call: 0,
    total_duration_call: 0,
    sms_metadata: {
      direction: [],
      dates: [],
    },
    call_metadata: {
      direction: [],
      is_answered: [],
      dates: [],
    },
  });
  const [commLog, setCommLog] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [inputs, setInputs] = useState({});
  const appState = useContext(StateContext);

  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(value);

    if (name === "organization") {
      console.log("here")
      if (liveDataBool) {
        setLiveData("restart")
      } else {
        setLiveData("abort")
      }
    }

    setInputs((values) => ({ ...values, [name]: value }));
    /*
      try {
        const commLogResponse = await axios.post(
            baseUrl + "/get-comm-logs-dashboard",
            {
              earliest_date: earliestDate,
              latest_date: latestDate,
              organization_id: value || "all",
            },
            {
              headers: {
                "x-access-token": appState.user.token,
                "Content-Type": "application/json",
              },
            },
            { withCredentials: true }
        );

        setCommLog(commLogResponse.data);
        calculateCommLogStats(commLogResponse.data);
      } catch (err) {
        console.log(err);
      }

     */
    }

  //This function calculates the basic comm log statistics.
  function calculateCommLogStats(log) {
    let commLogStat = {
      //Resets counters to zero for every new calculation
      total_num_calls: 0,
      total_answered_calls: 0,
      total_missed_calls: 0,
      total_sms: 0,
      total_sms_outgoing: 0,
      total_sms_incoming: 0,
      total_calls_outgoing: 0,
      total_calls_incoming: 0,
      total_outgoing: 0,
      total_incoming: 0,
      missed_call_percentage: 0,
      answered_call_percentage: 0,
      average_duration_call: 0,
      total_duration_call: 0,
      sms_metadata: {
        direction: [],
        dates: [],
      },
      call_metadata: {
        direction: [],
        is_answered: [],
        dates: [],
      },
    };

    if (log.length === 0) {
      //Sets everything to zero if comm log is empty
      setCommLogStat(commLogStat);
    }
    for (let i = 0; i < log.length; i++) {
      console.log("in the loop")
      //Runs a for loop through every comm log entry
      if (log[i].is_voice_call === "1") {
        //Checks the boolean values to see it comm log entry is call.
        commLogStat.total_num_calls = commLogStat.total_num_calls + 1;
      }
      if (log[i].is_voice_call === "1" && log[i].is_missed_call === "1") {
        commLogStat.total_missed_calls = commLogStat.total_missed_calls + 1;
        commLogStat.call_metadata.is_answered.push("0");
      }
      if (
        log[i].is_voice_call === "1" &&
        log[i].is_missed_call === "0" &&
        log[i].is_incoming === "1"
      ) {
        commLogStat.total_answered_calls = commLogStat.total_answered_calls + 1;
        commLogStat.call_metadata.is_answered.push("1");
      }
      if (log[i].is_sms === "1") {
        commLogStat.total_sms = commLogStat.total_sms + 1;
      }
      if (log[i].is_sms === "1" && log[i].is_outgoing === "1") {
        commLogStat.total_sms_outgoing = commLogStat.total_sms_outgoing + 1;
        commLogStat.sms_metadata.direction.push("outgoing");
        commLogStat.sms_metadata.dates.push(new Date(log[i].created_at));
      }
      if (log[i].is_sms === "1" && log[i].is_incoming === "1") {
        commLogStat.total_sms_incoming = commLogStat.total_sms_incoming + 1;
        commLogStat.sms_metadata.direction.push("incoming");
        commLogStat.sms_metadata.dates.push(new Date(log[i].created_at));
      }
      if (log[i].is_voice_call === "1" && log[i].is_outgoing === "1") {
        commLogStat.total_calls_outgoing = commLogStat.total_calls_outgoing + 1;
        commLogStat.call_metadata.direction.push("outgoing");
        commLogStat.call_metadata.dates.push(new Date(log[i].created_at));
      }
      if (log[i].is_voice_call === "1" && log[i].is_incoming === "1") {
        commLogStat.total_calls_incoming = commLogStat.total_calls_incoming + 1;
        commLogStat.call_metadata.direction.push("incoming");
        commLogStat.call_metadata.dates.push(new Date(log[i].created_at));
      }
      if (log[i].is_outgoing === "1") {
        commLogStat.total_outgoing = commLogStat.total_outgoing + 1;
      }
      if (log[i].is_incoming === "1") {
        commLogStat.total_incoming = commLogStat.total_incoming + 1;
      }
      if (log[i].duration !== null) {
        commLogStat.total_duration_call =
          commLogStat.total_duration_call + Number(log[i].duration);
      }
    }

    if (commLogStat.total_missed_calls === 0) {
      commLogStat.missed_call_percentage = 0;
    } else {
      commLogStat.missed_call_percentage =
        (commLogStat.total_num_calls / commLogStat.total_missed_calls) * 100;
    }

    if (commLogStat.total_answered_calls === 0) {
      commLogStat.answered_call_percentage = 0;
    } else {
      commLogStat.answered_call_percentage =
        (commLogStat.total_num_calls / commLogStat.total_answered_calls) * 100;
    }

    if (commLogStat.total_duration_call === 0) {
      commLogStat.average_duration_call = 0;
      console.log(commLogStat)
    } else {
      commLogStat.average_duration_call = parseInt(
        commLogStat.total_duration_call / commLogStat.total_answered_calls
      );
    }
      console.log(commLogStat)
      setCommLogStat(commLogStat);
      //return commLogStat;
  }

  async function handleSelectDateRange(event) {
    console.log(event.target.value)
    switch (event.target.value) {
      case "0":
        earliestDate.setDate(latestDate.getDate() - 1);
        setEarliestDate(earliestDate);
        setTimeInterval(1);
        setLiveData("restart");
        break;
      case "1":
        earliestDate.setDate(latestDate.getDate() - 3);
        setEarliestDate(earliestDate);
        setTimeInterval(3);
        setLiveData("restart");
        break;
      case "2":
        earliestDate.setDate(latestDate.getDate() - 7);
        setEarliestDate(earliestDate);
        setTimeInterval(7);
        setLiveData("abort");
        setLiveDataBool(false)
        break;
      case "3":
        earliestDate.setDate(latestDate.getDate() - 30);
        setEarliestDate(earliestDate);
        setTimeInterval(30);
        setLiveData("abort");
        setLiveDataBool(false)
        break;
      case "4":
        earliestDate.setDate(latestDate.getDate() - 90);
        setEarliestDate(earliestDate);
        setTimeInterval(90);
        setLiveData("abort");
        setLiveDataBool(false)
        break;
      case "5":
        earliestDate.setDate(latestDate.getDate() - 180);
        setEarliestDate(earliestDate);
        setTimeInterval(180);
        setLiveData("abort");
        setLiveDataBool(false)
        break;
      case "6":
        earliestDate.setDate(latestDate.getDate() - 365);
        setEarliestDate(earliestDate);
        setTimeInterval(365);
        setLiveData("abort");
        setLiveDataBool(false)
        break;
    }

    /*
    try {
      const commLogResponse = await axios.post(
        baseUrl + "/get-comm-logs-dashboard",
        {
          earliest_date: earliestDate,
          latest_date: latestDate,
          organization_id: inputs.organization || "all",
        },
        {
          headers: {
            "x-access-token": appState.user.token,
            "Content-Type": "application/json",
          },
        },
        { withCredentials: true }
      );

      setCommLog(commLogResponse.data);
      calculateCommLogStats(commLogResponse.data);
    } catch (err) {
      console.log(err);
    }

     */
  }

  async function handleEarliestDateRangeChange(event) {
    setLiveData("abort");
    setLiveDataBool(false)

    try {
      const commLogResponse = await axios.post(
        baseUrl + "/get-comm-logs-dashboard",
        {
          earliest_date: event,
          latest_date: latestDate,
          organization_id: inputs.organization || "all",
        },
        {
          headers: {
            "x-access-token": appState.user.token,
            "Content-Type": "application/json",
          },
        },
        { withCredentials: true }
      );

      setCommLog(commLogResponse.data);
      calculateCommLogStats(commLogResponse.data);
    } catch (err) {
      console.log(err);
    }

    setEarliestDate(event);
  }

  async function handleLiveDataCheckbox(event) {
    if (liveDataBool) {
      setLiveData("abort");
      setLiveDataBool(false)
    } else {
      setLiveData("start");
      setLiveDataBool(true)
    }
  }

  async function handleLatestDateRangeChange(event) {
    setLiveData("abort");
    setLiveDataBool(false)

    try {
      const commLogResponse = await axios.post(
        baseUrl + "/get-comm-logs-dashboard",
        {
          earliest_date: earliestDate,
          latest_date: event,
          organization_id: inputs.organization || "all",
        },
        {
          headers: {
            "x-access-token": appState.user.token,
            "Content-Type": "application/json",
          },
        },
        { withCredentials: true }
      );

      setCommLog(commLogResponse.data);
      calculateCommLogStats(commLogResponse.data);
    } catch (err) {
      console.log(err);
    }

    setLatestDate(event);
  }

  /*
  useEffect(() => {
    const fetchData = async () => {
      if (liveData === 'start' || liveData === 'restart') {
        if(liveData === 'restart') {
          console.log("Restarting listener... ")
          signal.abort()
          setSignal(signal)
        }
        const ctrl = new AbortController()
        setSignal(ctrl)

        await fetchEventSource(baseUrl + "/comm-log-event-listener", {
          method: "GET",
          openWhenHidden: true,
          headers: {
            Accept: "text/event-stream",
            "Content-Type": "application/json",
            "x-access-token": appState.user.token,
          },
          credentials: "include",
          signal: ctrl.signal,
          onmessage(event) {
            if (event.data !== "{}") {
              const parsedData = JSON.parse(event.data);

              console.log(!inputs.organization)
              if (
                  inputs.organization === "all" ||
                  !inputs.organization ||
                  inputs.organization === parsedData.organization_id
              ) {
                console.log("update")
                commLog.push(parsedData);
                calculateCommLogStats(commLog);
                setCommLog(commLog);
              }

              console.log(commLog);
              toast({
                position: "top",
                duration: 15000,
                render: () => (
                    <Box
                        padding={"2%"}
                        borderRadius={"4px"}
                        boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
                        margin={"2%"}
                        alignItems="center"
                        color="white"
                        p={3}
                        bg="blue.500"
                        opacity={"0.8"}
                    >
                      {parsedData.is_incoming === "1" ? (
                          <Badge color={"green"}>Incoming</Badge>
                      ) : (
                          <Badge color={"blue"}>Outgoing</Badge>
                      )}
                      {parsedData.is_voice_call === "1" ? (
                          <Text opacity={"1"}>
                            {parsedData.is_missed_call === "0" ? (
                                <>
                                  <Badge>Call</Badge>
                                  <Text>
                                    {parsedData.duration === null ? (
                                        <p>Duration: N/A</p>
                                    ) : (
                                        <p>Duration: {parsedData.duration} seconds</p>
                                    )}
                                  </Text>
                                </>
                            ) : (
                                <Badge colorScheme="red">New Missed Call</Badge>
                            )}
                          </Text>
                      ) : parsedData.is_sms === "1" ? (
                          <>
                            <Text>New SMS</Text>
                            <Text>{parsedData.report}</Text>
                          </>
                      ) : (
                          <Text>None</Text>
                      )}
                      <Text>Phone: {parsedData.customer_phone} </Text>
                      <Text>Caller ID: {parsedData.customer_caller_id}</Text>
                    </Box>
                ),
              });
            }
          },
          onerror(err) {
            console.log("There was an error from server", err);
          },
          onclose() {
            console.log("Connection closed by the server");
          },
        });
      } else if(liveData === 'abort') {
        console.log("Listener stopped.");
        signal.abort();
        setSignal(signal)
      }
    }
    fetchData();
  }, [liveDataBool, liveData]);
*/

  useEffect(() => {
    async function fetchOrganizations() {
      try {
        const organizationsResponse = await axios.get(
          baseUrl + "/get-agent-organizations",
          {
            headers: {
              "x-access-token": appState.user.token,
            },
          },
          { withCredentials: true }
        );

        console.log(organizationsResponse.data);
        setOrganizations(organizationsResponse.data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchOrganizations();
  }, []);

  /*
  useEffect(() => {
    if (!timerBool) {
      return;
    }
    const interval = setInterval(() => {
      setTimerTrigger((timeTrigger) => timeTrigger + 1);
    }, 10000);
    return () => clearInterval(interval);
  }, [timerBool]);
   */

  useEffect(() => {
    async function fetchCommLog() {
      const latest_datetime = new Date();
      const earliest_datetime = new Date(latest_datetime);
      earliest_datetime.setDate(latest_datetime.getDate() - timeInterval);
      console.log("Comm Log updated");

      try {
        const commLogResponse = await axios.post(
          baseUrl + "/get-comm-logs-dashboard",
          {
            earliest_date: earliest_datetime,
            latest_date: latest_datetime,
            organization_id: inputs.organization || "all",
          },
          {
            headers: {
              "x-access-token": appState.user.token,
              "Content-Type": "application/json",
            },
          },
          { withCredentials: true }
        );

        calculateCommLogStats(commLogResponse.data);
        setEarliestDate(earliest_datetime)
        setLatestDate(latest_datetime)
        setCommLog(commLogResponse.data);
        console.log(commLogResponse.data)

        if (liveData === 'start' || liveData === 'restart') {
          if(liveData === 'restart') {
            console.log("Restarting listener... ")
            signal.abort()
            setSignal(signal)
          }
          const ctrl = new AbortController()
          setSignal(ctrl)

          await fetchEventSource(baseUrl + "/comm-log-event-listener", {
            method: "GET",
            openWhenHidden: true,
            headers: {
              Accept: "text/event-stream",
              "Content-Type": "application/json",
              "x-access-token": appState.user.token,
            },
            credentials: "include",
            signal: ctrl.signal,
            onmessage(event) {
              if (event.data !== "{}") {
                const parsedData = JSON.parse(event.data);

                console.log(!inputs.organization)
                if (
                  inputs.organization === "all" ||
                  !inputs.organization ||
                  inputs.organization === parsedData.organization_id
                ) {
                  console.log("update")
                  commLogResponse.data.push(parsedData);
                  calculateCommLogStats(commLogResponse.data);
                  setCommLog(commLogResponse.data);
                }

                console.log(commLogResponse.data);
                toast({
                  position: "top",
                  duration: 5000,
                  render: () => (
                    <Box
                      padding={"2%"}
                      borderRadius={"4px"}
                      boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
                      margin={"2%"}
                      alignItems="center"
                      color="white"
                      p={3}
                      bg="blue.500"
                      opacity={"0.8"}
                    >
                      {parsedData.is_incoming === "1" ? (
                        <Badge color={"green"}>Incoming</Badge>
                      ) : (
                        <Badge color={"blue"}>Outgoing</Badge>
                      )}
                      {parsedData.is_voice_call === "1" ? (
                        <Text opacity={"1"}>
                          {parsedData.is_missed_call === "0" ? (
                            <>
                              <Badge>Call</Badge>
                              <Text>
                                {parsedData.duration === null ? (
                                  <p>Duration: N/A</p>
                                ) : (
                                  <p>Duration: {parsedData.duration} seconds</p>
                                )}
                              </Text>
                            </>
                          ) : (
                            <Badge colorScheme="red">New Missed Call</Badge>
                          )}
                        </Text>
                      ) : parsedData.is_sms === "1" ? (
                        <>
                          <Text>New SMS</Text>
                          <Text>{parsedData.report}</Text>
                        </>
                      ) : (
                        <Text>None</Text>
                      )}
                      <Text>Phone: {parsedData.customer_phone} </Text>
                      <Text>Caller ID: {parsedData.customer_caller_id}</Text>
                      <Box margin={"2%"} padding={"2%"}>
                        {parsedData.contact_id ? (
                            <ContactInfoBoxModal commLog={parsedData} />
                        ) : (
                            <Box>
                              <Text>No contact information found.</Text>{" "}
                              <ContactFormModal
                                  formType={"add"}
                                  appState={appState}
                                  commLog={parsedData}
                              />
                            </Box>
                        )}
                      </Box>
                    </Box>
                  ),
                });
              }
            },
            onerror(err) {
              console.log("There was an error from server", err);
            },
            onclose() {
              console.log("Connection closed by the server");
            },
          });
        } else if(liveData === 'abort') {
          console.log("Listener stopped.");
          signal.abort();
          setSignal(signal)
        }
      } catch (e) {
        console.log("There was a problem");
      }
    }
    fetchCommLog();
  }, [timeInterval, liveDataBool, liveData, inputs.organization]);

  return (
    <div className={"background-crm"}>
      <Box
        padding={"2%"}
        borderRadius={"4px"}
        boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
        margin={"2%"}
        bg="white"
        w="95%"
      >
        <Wrap alignItems={"center"} flexDirection={"row"}>
          <Select onChange={handleSelectDateRange} width={"200px"}>
            <option value="0">Past Day</option>
            <option value="1">Past 3 Days</option>
            <option value="2">Past Week</option>
            <option value="3">Past 30 Days</option>
            <option value="4">Past 90 Days</option>
            <option value="5">Past 180 Days</option>
            <option value="6">Past 365 Days</option>
          </Select>
          <Select
            onChange={handleChange}
            defaultValue={"all"}
            name={"organization"}
            width={"200px"}
          >
            <option value={"all"}>All Properties</option>
            {organizations.map((organization) => {
              return (
                <option value={organization.organization_id}>
                  {organization.organization_name}
                </option>
              );
            })}
          </Select>

          <DateTimePicker
            fontSize={{ base: "8px", md: "12px", lg: "14px" }}
            onChange={handleEarliestDateRangeChange}
            maxDate={latestDate}
            value={earliestDate}
            disableClock={true}
          />
          <p>-</p>
          <DateTimePicker
            fontSize={{ base: "8px", md: "12px", lg: "14px" }}
            onChange={handleLatestDateRangeChange}
            minDate={earliestDate}
            value={latestDate}
            disableClock={true}
          />
          <DownloadReportComponent
            commLog={commLog}
            earliestDate={earliestDate}
            latestDate={latestDate}
          />
          <DownloadStatReportComponent />
          <Checkbox isChecked={liveDataBool} onChange={handleLiveDataCheckbox}>
            <Badge colorScheme="red">LIVE DATA</Badge>
          </Checkbox>
        </Wrap>
      </Box>

      <Box
        padding={"2%"}
        margin={"2%"}
        borderRadius={"4px"}
        boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
        bg="white"
        w="95%"
      >
        <Heading size="lg" align={"center"}>
          Call Analysis
        </Heading>
        <br />
        <Wrap justify="space-evenly">
          <WrapItem>
            <CallStatBox commLogStat={commLogStat} />
          </WrapItem>
          <WrapItem>
            <BarGraphCallLog commLogStat={commLogStat} />
          </WrapItem>
          <WrapItem>
            <LineGraphCallLog
              commLogStat={commLogStat}
              earliestDate={earliestDate}
              latestDate={latestDate}
            />
          </WrapItem>
          <WrapItem>
            <RecentCallViewer commLog={commLog} />
          </WrapItem>
          <WrapItem>
            <DonutGraphMissedCalls commLogStat={commLogStat} />
          </WrapItem>
        </Wrap>
        <br />
      </Box>

      <Box
        padding={"2%"}
        margin={"2%"}
        borderRadius={"4px"}
        boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
        bg="white"
        w="95%"
      >
        <Heading size="lg" align={"center"}>
          SMS Analysis
        </Heading>
        <br />
        <Wrap justify="space-evenly">
          <WrapItem>
            <SMSStatBox commLogStat={commLogStat} />
          </WrapItem>
          <WrapItem>
            <BarGraphSMSLog commLogStat={commLogStat} />
          </WrapItem>
          <WrapItem>
            <LineGraphSMSLog
              commLogStat={commLogStat}
              earliestDate={earliestDate}
              latestDate={latestDate}
            />
          </WrapItem>
          <WrapItem>
            <RecentSMSViewer commLog={commLog} />
          </WrapItem>
        </Wrap>
      </Box>
      <Box
        padding={"2%"}
        margin={"2%"}
        borderRadius={"4px"}
        boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
        bg="white"
        w="95%"
      >
        <CommLogTable
          commLog={commLog}
          setCommLog={setCommLog}
        />
      </Box>
    </div>
  );
};

export default Dashboard;
