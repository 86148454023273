import React from "react";
import Chart from "react-apexcharts";
import { AccordionPanel, Badge, Box, Text } from "@chakra-ui/react";
import ContactInfoBox from "./ContactInfoBox";
import ContactFormModal from "./ContactFormModal";
import ContactInfoBoxModal from "./ContactInfoBoxModal";

function timeSince(time) {
  switch (typeof time) {
    case "number":
      break;
    case "string":
      time = +new Date(time);
      break;
    case "object":
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  let time_formats = [
    [60, "seconds", 1], // 60
    [120, "1 minute ago", "1 minute from now"], // 60*2
    [3600, "minutes", 60], // 60*60, 60
    [7200, "1 hour ago", "1 hour from now"], // 60*60*2
    [86400, "hours", 3600], // 60*60*24, 60*60
    [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
    [604800, "days", 86400], // 60*60*24*7, 60*60*24
    [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
    [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
    [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
    [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
    [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  var seconds = (+new Date() - time) / 1000,
    token = "ago",
    list_choice = 1;

  if (seconds === 0) {
    return "Just now";
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = "from now";
    list_choice = 2;
  }
  let i = 0,
    format;
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] == "string") return format[list_choice];
      else
        return Math.floor(seconds / format[2]) + " " + format[1] + " " + token;
    }
  return time;
}

export function BarGraphCallLog(props) {
  let series = [
    {
      name: "Number of Calls", //will be displayed on the y-axis
      data: [
        props.commLogStat.total_calls_incoming,
        props.commLogStat.total_calls_outgoing,
      ],
    },
  ];
  let options = {
    chart: {
      id: "simple-bar",
    },
    title: {
      text: "Call Direction",
      align: "center",
    },
    xaxis: {
      categories: ["Incoming Calls", "Outgoing Calls"], //will be displayed on the x-asis
    },
  };

  return (
    <Box
      boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
      padding={"2%"}
      bg="white"
      borderRadius={"4px"}
      w="290px"
    >
      <Chart options={options} type="bar" series={series} width="290" />
    </Box>
  );
}

export function DonutGraphMissedCalls(props) {
  const series = [
    props.commLogStat.total_missed_calls,
    props.commLogStat.total_answered_calls,
  ]; //70 percent
  const options = {
    labels: ["Missed Calls", "Answered Calls"], //label of this diagram
    title: {
      text: "Call Response",
      align: "center",
    },
  };
  return (
    <Box
      boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
      padding={"2%"}
      bg="white"
      borderRadius={"4px"}
      w="290px"
    >
      <Chart type="pie" series={series} options={options} width="290" />
    </Box>
  );
}

export function BarGraphSMSLog(props) {
  let series = [
    {
      name: "Number of SMS", //will be displayed on the y-axis
      data: [
        props.commLogStat.total_sms_incoming,
        props.commLogStat.total_sms_outgoing,
      ],
    },
  ];
  let options = {
    chart: {
      id: "simple-bar",
    },
    title: {
      text: "SMS Direction",
      align: "center",
    },
    xaxis: {
      categories: ["Incoming SMS", "Outgoing SMS"], //will be displayed on the x-asis
    },
  };

  return (
    <Box
      boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
      padding={"2%"}
      bg="white"
      borderRadius={"4px"}
      w="290px"
    >
      <Chart options={options} type="bar" series={series} width="290" />
    </Box>
  );
}

export function LineGraphCallLog(props) {
  const callDates = props.commLogStat.call_metadata.dates;
  let callsPerHourObject = {};
  let hour = "";

  for (let i = 0; i < callDates.length; i++) {
    callDates[i].setHours(callDates[i].getHours());
    callDates[i].setMinutes(0, 0, 0);
    hour = callDates[i];
    if (callsPerHourObject[hour]) {
      callsPerHourObject[hour] = callsPerHourObject[hour] + 1;
    } else {
      callsPerHourObject[hour] = 1;
    }
  }
  let unixTimestamp = 0;
  let callsPerHourSeries = [];

  for (let key of Object.keys(callsPerHourObject)) {
    unixTimestamp = new Date(key).getTime();
    callsPerHourSeries.push([unixTimestamp, callsPerHourObject[key]]);
  }

  let series = [
    {
      name: "Call Activity",
      data: callsPerHourSeries,
    },
  ];

  let options = {
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    title: {
      text: "Call Activity",
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  return (
    <Box
      boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
      padding={"2%"}
      bg="white"
      borderRadius={"4px"}
      w="290px"
    >
      <Chart options={options} series={series} height={350} />
    </Box>
  );
}

export function LineGraphSMSLog(props) {
  const smsDates = props.commLogStat.sms_metadata.dates;
  let smsPerHourObject = {};
  let hour = "";

  for (let i = 0; i < smsDates.length; i++) {
    smsDates[i].setHours(smsDates[i].getHours());
    smsDates[i].setMinutes(0, 0, 0);
    hour = smsDates[i];
    if (smsPerHourObject[hour]) {
      smsPerHourObject[hour] = smsPerHourObject[hour] + 1;
    } else {
      smsPerHourObject[hour] = 1;
    }
  }
  let unixTimestamp = 0;
  let smsPerHourSeries = [];

  for (let key of Object.keys(smsPerHourObject)) {
    unixTimestamp = new Date(key).getTime();
    smsPerHourSeries.push([unixTimestamp, smsPerHourObject[key]]);
  }

  let series = [
    {
      name: "SMS Activity",
      data: smsPerHourSeries,
    },
  ];

  let options = {
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    title: {
      text: "SMS Activity",
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  return (
    <Box
      boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
      padding={"2%"}
      bg="white"
      borderRadius={"4px"}
      w="290px"
    >
      <Chart options={options} series={series} height={350} />
    </Box>
  );
}

export function CallStatBox(props) {
  return (
    <Box
      boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
      padding={"2%"}
      bg="white"
      borderRadius={"4px"}
      w="290px"
    >
      <Text align={"center"} fontSize="lg">
        <b>Call Statistics</b>
      </Text>
      <Text>Total Number of Calls: {props.commLogStat.total_num_calls}</Text>
      <Text>
        Total Number of Answered Calls: {props.commLogStat.total_answered_calls}
      </Text>
      <Text>
        Total Number of Missed Calls: {props.commLogStat.total_missed_calls}
      </Text>
      <Text>
        Total Number of Incoming Calls: {props.commLogStat.total_calls_incoming}
      </Text>
      <Text>
        Total Number of Outgoing Calls: {props.commLogStat.total_calls_outgoing}
      </Text>
      <Text>
        Average Duration of Call:{" "}
        {(props.commLogStat.average_duration_call / 60).toFixed(2)} minutes
      </Text>
      <Text>
        Total Time of Calls:{" "}
        {(props.commLogStat.total_duration_call / 60).toFixed(2)} minutes
      </Text>
    </Box>
  );
}

export function SMSStatBox(props) {
  return (
    <Box
      boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
      padding={"2%"}
      bg="white"
      borderRadius={"4px"}
      w="290px"
    >
      <Text align={"center"} fontSize="lg">
        <b>SMS Statistics</b>
      </Text>
      <Text>Total Number of SMS: {props.commLogStat.total_sms}</Text>
      <Text>
        Total Number of Incoming SMS: {props.commLogStat.total_sms_incoming}
      </Text>
      <Text>
        Total Number of Outgoing SMS: {props.commLogStat.total_sms_outgoing}
      </Text>
    </Box>
  );
}

export function RecentSMSViewer(props) {
  let smsLog = [];

  for (let i = 0; i < props.commLog.length; i++) {
    if (props.commLog[i].is_sms === "1") {
      smsLog.push(props.commLog[i]);
    }
  }
  smsLog.sort(function compare(a, b) {
    let dateA = new Date(a.created_at);
    let dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <Box
      boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
      padding={"2%"}
      bg="white"
      borderRadius={"4px"}
      w="290px"
    >
      <Text align={"center"} fontSize="lg">
        <b> Recent SMS</b>
      </Text>
      {smsLog.length > 0 ? (
        <Box h={"400px"} overflow={"scroll"}>
          {smsLog.map((smsLog) => {
            return (
              <>
                <Box
                  boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
                  padding={"2%"}
                  bg="whitesmoke"
                  borderRadius={"4px"}
                  w="260px"
                >
                  <Text>Phone #: {smsLog.customer_phone}</Text>
                  <Text>Caller ID: {smsLog.customer_caller_id}</Text>
                  {smsLog.is_incoming === "1" ? (
                    <Badge color={"green"}>Incoming</Badge>
                  ) : (
                    <Badge color={"blue"}>Outgoing</Badge>
                  )}
                  <Text>{smsLog.report}</Text>
                  <Box margin={"2%"} padding={"2%"}>
                    {smsLog.contact_id ? (
                      <ContactInfoBoxModal commLog={smsLog} />
                    ) : (
                      <>
                        <p>No contact information found.</p>{" "}
                        <ContactFormModal commLog={smsLog} />{" "}
                      </>
                    )}
                  </Box>
                  <Text size={"sm"}>
                    <b>{timeSince(smsLog.createdAt)}</b>
                  </Text>
                </Box>
                <br />
              </>
            );
          })}
        </Box>
      ) : (
        <Text>No SMS Data</Text>
      )}
    </Box>
  );
}

export function RecentCallViewer(props) {
  let callLog = [];

  for (let i = 0; i < props.commLog.length; i++) {
    if (props.commLog[i].is_voice_call === "1") {
      callLog.push(props.commLog[i]);
    }
  }
  callLog.sort(function compare(a, b) {
    let dateA = new Date(a.created_at);
    let dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <Box
      boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
      padding={"2%"}
      bg="white"
      borderRadius={"4px"}
      w="290px"
    >
      <Text align={"center"} fontSize="lg">
        <b>Recent Calls</b>
      </Text>
      {callLog.length > 0 ? (
        <Box h={"400px"} overflow={"scroll"}>
          {callLog.map((callLog) => {
            return (
              <>
                <Box
                  boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
                  padding={"2%"}
                  bg="whitesmoke"
                  borderRadius={"4px"}
                  w="260px"
                >
                  <Text>Phone #: {callLog.customer_phone}</Text>
                  <Text>Caller ID: {callLog.customer_caller_id}</Text>
                  {callLog.is_missed_call === "1" ? (
                    <Badge colorScheme="red">Missed Call</Badge>
                  ) : (
                    <>
                      <Text>
                        {callLog.duration === null ? (
                          <p>Duration: N/A</p>
                        ) : (
                          <p>Duration: {callLog.duration} seconds</p>
                        )}
                      </Text>
                    </>
                  )}
                  {callLog.is_incoming === "1" ? (
                    <Badge color={"green"}>Incoming</Badge>
                  ) : (
                    <Badge color={"blue"}>Outgoing</Badge>
                  )}
                  <Box margin={"2%"} padding={"2%"} alignItems={'center'}>
                    {callLog.contact_id ? (
                        <ContactInfoBoxModal commLog={callLog} />
                    ) : (
                      <>
                        {" "}
                        <p>No contact information found.</p>{" "}
                        <ContactFormModal commLog={callLog} />{" "}
                      </>
                    )}
                  </Box>
                  <Text size={"sm"}>
                    <b>{timeSince(callLog.createdAt)}</b>
                  </Text>
                </Box>
                <br />
              </>
            );
          })}
        </Box>
      ) : (
        <Text>No Call Data</Text>
      )}
    </Box>
  );
}
