import { Navigate, Outlet } from 'react-router';
import StateContext from './StateContext';
import { useContext } from 'react';
import { isExpired } from "react-jwt";
import {AppContext} from "./Context";
import {useNavigate} from "react-router-dom";
import Navbar from "./components/Navbar";

const useAuth = () => {
  const appState = useContext(StateContext);
  const auth = {loggedIn: appState.loggedIn}
  return auth;
}

const CheckTokenExpirationMiddleware = () => {
  const appDispatch = useContext(AppContext);
  const token = localStorage.getItem('token');
  let navigate = useNavigate();
  const isMyTokenExpired = isExpired(token);

  if(isMyTokenExpired) {
    console.log("Token is expired.");
    appDispatch({ type: 'logout' });
    navigate('/');
    return false;
  }
  return true;
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();
  const tokenCheck = CheckTokenExpirationMiddleware();

  return (isAuth.loggedIn && tokenCheck) ? <div> <Navbar /> <Outlet /> </div>
      :  <Navigate to={'/'} />
}

export default ProtectedRoutes;
