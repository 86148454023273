import React, { useState } from "react";
import { Button, Select } from "@chakra-ui/react";
/*
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,

} from "@react-pdf/renderer";
import ReactPDF from '@react-pdf/renderer';

 */
import { DownloadIcon } from "@chakra-ui/icons";

export function DownloadStatReportComponent(props) {
    const [reportFormat, setReportFormat] = useState("pdf");

    async function handleReportFormatType(event) {
        const format = event.target.value;
        console.log(format);
        setReportFormat(format);
    }

    async function handleDownloadStatReport(event) {
    }

    return (
        <>
            <Button
                colorScheme="teal"
                leftIcon={<DownloadIcon />}
                onClick={handleDownloadStatReport}
            >
                Statistics Report
            </Button>
            <Select width={"150px"} onChange={handleReportFormatType}>
                <option value="pdf">PDF</option>
            </Select>
        </>
    );
}
