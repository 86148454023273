import React, { useContext, useState } from 'react';
import { omit } from 'lodash';
import axios from 'axios';
import { AppContext } from './Context';
import { useToast } from '@chakra-ui/react';
import publicIp from "public-ip";
const baseUrl = process.env.REACT_APP_BASEURL;

const useForm = (callback) => {
  axios.defaults.withCredentials = true;
  const toast = useToast()
  const appDispatch = useContext(AppContext);
  //Form values
  const [values, setValues] = useState({});
  //Errors
  const [errors, setErrors] = useState({});
  const [failedLogin, setFailedLogin] = useState(false);

  const validate = (event, name, value) => {
    console.log(errors);
    //A function to validate each input values
    switch (name) {
      case 'Email':
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: 'Enter a valid email address'
          })
        } else {
          let newObj = omit(errors, 'email');
          setErrors(newObj);
        }
        break;

      case 'Password':
        if (!new RegExp(/^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)) {
          setErrors({
            ...errors,
            password: 'Password should contains at least 8 characters with an uppercase and lowercase and numbers'
          })
        } else {
          let newObj = omit(errors, 'password');
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  }

  //A method to handle form inputs
  const handleChange = (event) => {
    //To stop default events
    event.persist();

    let name = event.target.name;
    let val = event.target.value;

    validate(event, name, val);
    //Let's set these values in state
    setValues((values) => ({ ...values, [name]: val }));
  }

  const handleSubmit = async (event) => {
    if (event) event.preventDefault()
    const getClientIPV4 = await publicIp.v4();
    let getClientIPV6 = "";

    try {
      getClientIPV6 = await publicIp.v6();
    } catch(e) {
      console.log("Cannot get IPV6");
    }

    try {
      const response = await axios.post(
        baseUrl + '/login',
        {
          client_ip_address_v4: getClientIPV4,
          client_ip_address_v6: getClientIPV6,
          email: values.Email,
          password: values.Password
        },
        { withCredentials: true }
      );
      setFailedLogin(false);
      toast({
        title: 'Login success.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      console.log(response.data)
      if (response.data && Object.keys(errors).length === 0 && Object.keys(values).length !== 0) {
        callback();
        appDispatch({ type: 'login', data: response.data });
      }
    } catch (e) {
      console.log(e);
      toast({
        title: 'Login failed. Invalid credentials',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setFailedLogin(true);
    }
  }

  return {
    values,
    errors,
    failedLogin,
    handleChange,
    handleSubmit
  }
}

export default useForm;
