import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import ContactFormModal from "./ContactFormModal";
import ContactInfoBox from "./ContactInfoBox";
import React, { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import ActivityLog from "./ActivityLog";

const tableIcons = {
  Add: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <AddBox {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Check {...props} ref={ref} />
  )),
  Clear: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Clear {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Edit {...props} ref={ref} />
  )),
  Export: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <SaveAlt {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <FilterList {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <LastPage {...props} ref={ref} />
  )),
  NextPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Search {...props} ref={ref} />
  )),
  SortArrow: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ArrowUpward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

export default function CommLogTable(props) {
  return (
    <MaterialTable
      title="Communication Log"
      columns={[
        { title: "Phone", field: "customer_phone" },
        { title: "Caller ID", field: "customer_caller_id" },
        { title: "Missed", field: "is_missed_call" },
        { title: "Call", field: "is_voice_call" },
        { title: "SMS", field: "is_sms" },
        { title: "Timestamp", field: "created_at" },
      ]}
      data={props.commLog}
      detailPanel={[
        {
          render: (commLog) => {
            return (
              <Box w="95%">
                <Accordion allowToggle allowMultiple>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box textAlign="center">
                          <Heading size={"md"}>Contact Information</Heading>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Box margin={"2%"} padding={"2%"}>
                        {commLog.contact_id ? (
                          <ContactInfoBox
                            commLog={commLog}
                            allCommLog={props.commLog}
                            setCommLog={props.setCommLog}
                          />
                        ) : (
                          <>
                            <Wrap>
                              <WrapItem>
                                <Box>
                                  <Text>No contact information found.</Text>{" "}
                                  <ContactFormModal
                                    formType={"add"}
                                    allCommLog={props.commLog}
                                    commLog={commLog}
                                    setCommLog={props.setCommLog}
                                  />
                                </Box>
                              </WrapItem>
                              <WrapItem>
                                <ActivityLog phone={commLog.customer_phone} />
                              </WrapItem>
                            </Wrap>
                          </>
                        )}
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box textAlign="center">
                          <Heading size={"md"}>Metadata</Heading>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Box
                        padding="2%"
                        bg="whitesmoke"
                        borderRadius={"4px"}
                        boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
                        margin={"2%"}
                      >
                        <Text>
                          <b>Call Sid:</b> {commLog.call_sid || "N/A"}
                        </Text>
                        <Text>
                          <b>Contacted ID:</b> {commLog.contacted_id || "N/A"}
                        </Text>
                        <Text>
                          <b>Contacted Note:</b>{" "}
                          {commLog.contacted_note || "N/A"}
                        </Text>
                        <Text>
                          <b>Created At:</b> {commLog.created_at || "N/A"}
                        </Text>
                        <Text>
                          <b>Customer Caller ID:</b>{" "}
                          {commLog.customer_caller_id || "N/A"}
                        </Text>
                        <Text>
                          <b>Customer ID:</b> {commLog.customer_id || "N/A"}
                        </Text>
                        <Text>
                          <b>Customer Phone:</b> {commLog.customer_phone}
                        </Text>
                        <Text>
                          <b>Deleted At:</b> {commLog.deleted_at || "N/A"}
                        </Text>
                        <Text>
                          <b>Duration:</b> {commLog.duration || "N/A"}
                        </Text>
                        <Text>
                          <b>Forwarded From:</b>{" "}
                          {commLog.forwarded_from || "N/A"}
                        </Text>
                        <Text>
                          <b>Group ID:</b> {commLog.group_id || "N/A"}
                        </Text>
                        <Text>
                          <b>ID:</b> {commLog.id || "N/A"}
                        </Text>
                        <Text>
                          <b>Is Archive:</b> {commLog.is_archive || "N/A"}
                        </Text>
                        <Text>
                          <b>Is Image:</b> {commLog.is_image || "N/A"}
                        </Text>
                        <Text>
                          <b>Is Incoming:</b> {commLog.is_incoming || "N/A"}
                        </Text>
                        <Text>
                          <b>Is Missed Call:</b>{" "}
                          {commLog.is_missed_call || "N/A"}
                        </Text>
                        <Text>
                          <b>Is Outgoing:</b> {commLog.is_outgoing || "N/A"}
                        </Text>
                        <Text>
                          <b>Is SMS:</b> {commLog.is_sms || "N/A"}
                        </Text>
                        <Text>
                          <b>Is Voice Call:</b> {commLog.is_voice_call || "N/A"}
                        </Text>
                        <Text>
                          <b>Lead ID:</b> {commLog.lead_id || "N/A"}
                        </Text>
                        <Text>
                          <b>Line Type:</b> {commLog.line_type || "N/A"}
                        </Text>
                        <Text>
                          <b>Maint User ID:</b> {commLog.maint_user_id || "N/A"}
                        </Text>
                        <Text>
                          <b>Organization ID:</b>{" "}
                          {commLog.organization_id || "N/A"}
                        </Text>
                        <Text>
                          <b>Property ID:</b> {commLog.property_id || "N/A"}
                        </Text>
                        <Text>
                          <b>Prospect ID:</b> {commLog.prospect_id || "N/A"}
                        </Text>
                        <Text>
                          <b>Report:</b> {commLog.report || "N/A"}
                        </Text>
                        <Text>
                          <b>System Phone:</b> {commLog.system_phone || "N/A"}
                        </Text>
                        <Text>
                          <b>Tags:</b> {commLog.tags || "N/A"}
                        </Text>
                        <Text>
                          <b>Tenant ID:</b> {commLog.tenant_id || "N/A"}
                        </Text>
                        <Text>
                          <b>Updated At:</b> {commLog.updated_at || "N/A"}
                        </Text>
                        <Text>
                          <b>User ID:</b> {commLog.user_id || "N/A"}
                        </Text>
                        <Text>
                          <b>Wo ID:</b> {commLog.wo_id || "N/A"}
                        </Text>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            );
          },
        },
      ]}
      options={{
        searchAutoFocus: true,
        headerStyle: {
          fontSize: 14,
        },
        rowStyle: {
          fontSize: 14,
        },
        sort: true,
      }}
      icons={tableIcons}
    />
  );
}
