import React, { useContext } from "react";
import { Link } from "react-router-dom";
import StateContext from "../StateContext";
import { Box, Flex, Tabs, Tab, Wrap } from "@chakra-ui/react";

const useAuth = () => {
  const appState = useContext(StateContext); //Add axios request to get role of user
  if (appState.user.role === "admin") {
    return true;
  }
  return false;
};

export default function MenuBar() {
  const isAuth = useAuth();

  return (
    <Box color={"black"}>
      <Flex bg={"gray.100"}>
        <Tabs size="sm" variant="unstyled">
          <Wrap overflow={"hidden"}>
            <Link to={"/comm-log-dashboard"}>
              <Tab
                fontSize={{ base: "14px", md: "16px", lg: "18px" }}
                _selected={{ color: "black", bg: "blue.500" }}
              >
                <Link to={"/comm-log-dashboard"}>Communications</Link>
              </Tab>
            </Link>
            <Link to={"/leasing-dashboard"}>
              <Tab
                fontSize={{ base: "14px", md: "16px", lg: "18px" }}
                _selected={{ color: "black", bg: "blue.500" }}
              >
                <Link to={"/leasing-dashboard"}>Leasing</Link>
              </Tab>
            </Link>
            <Link to={"/contacts-dashboard"}>
              <Tab
                  fontSize={{ base: "14px", md: "16px", lg: "18px" }}
                  _selected={{ color: "black", bg: "blue.500" }}
              >
                <Link to={"/contacts-dashboard"}>Contacts</Link>
              </Tab>
            </Link>
            {isAuth ? (
              <>
                <Link to={"/admin/view-agents"}>
                  <Tab
                    fontSize={{ base: "14px", md: "16px", lg: "18px" }}
                    _selected={{ color: "black", bg: "blue.500" }}
                  >
                    <Link to={"/admin/view-agents"}>test admin</Link>
                  </Tab>
                </Link>
              </>
            ) : null}
          </Wrap>
        </Tabs>
      </Flex>
    </Box>
  );
}
