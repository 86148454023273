import React, { useState } from "react";
import { Button, Select } from "@chakra-ui/react";
import exportFromJSON from "export-from-json";
import { DownloadIcon } from "@chakra-ui/icons";

export function DownloadReportComponent(props) {
  const [reportFormat, setReportFormat] = useState("csv");

  async function handleReportFormatType(event) {
    const format = event.target.value;
    console.log(format);
    setReportFormat(format);
  }

  async function handleDownloadReport(event) {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const fileName =
      "comm_log_report_" +
      props.earliestDate.toLocaleDateString("en-US", options) +
      "-" +
      props.latestDate.toLocaleDateString("en-US", options);
    let exportType = exportFromJSON.types.csv;

    switch (reportFormat) {
      case "csv":
        exportType = exportFromJSON.types.csv;
        break;
      case "json":
        exportType = exportFromJSON.types.json;
        break;
      case "txt":
        exportType = exportFromJSON.types.txt;
        break;
      case "xls":
        exportType = exportFromJSON.types.xls;
        break;
    }
    exportFromJSON({ data: props.commLog, fileName, exportType });
  }
  return (
    <>
      <Button
        colorScheme="teal"
        leftIcon={<DownloadIcon />}
        onClick={handleDownloadReport}
      >
        Data Report
      </Button>
      <Select width={"150px"} onChange={handleReportFormatType}>
        <option value="csv">CSV</option>
        <option value="json">JSON</option>
        <option value="txt">TXT</option>
        <option value="xls">XLS (Excel)</option>
      </Select>
    </>
  );
}
