// ts
import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import publicIp from "public-ip";
const baseUrl = process.env.REACT_APP_BASEURL;

function ParkingForm(props) {
  axios.defaults.withCredentials = true;
  const params = useParams();
  const toast = useToast();
  const [inputs, setInputs] = useState({ internationalCode: "+1" });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    console.log(name);
    console.log(value);
    setInputs((values) => ({ ...values, [name]: value }));
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const getClientIPV4 = await publicIp.v4();
    let getClientIPV6 = "";

    try {
      getClientIPV6 = await publicIp.v6();
    } catch (e) {
      console.log("Cannot get IPV6");
    }

    try {
      await axios.post(
        baseUrl + "/create-parking-form",
        {
          first_name: inputs.firstName,
          middle_name: inputs.middleName,
          last_name: inputs.lastName,
          phone: inputs.internationalCode + inputs.phoneNumber,
          email: inputs.email,
          address: inputs.address,
          unit: inputs.unit,
          parking_spot: inputs.parkingSpot,
          vehicle_make: inputs.make,
          vehicle_model: inputs.model,
          vehicle_trim: inputs.trim,
          vehicle_color: inputs.color,
          vehicle_year: inputs.year,
          vehicle_license_plate: inputs.licensePlate,
          vehicle_vin: inputs.vin,
          ip_address_v4: getClientIPV4,
          ip_address_v6: getClientIPV6,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
        { withCredentials: true }
      );
      setIsSubmitted(true);
      toast({
        title: "Your information is saved! Thank you and have a nice day :)",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      setIsSubmitted(false);
      toast({
        title: "An error has occurred. Please try again. :(",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.log(e);
    }
  }

  return (
    <div className={"login-background"}>
      <Flex
        justify={"center"}
        align={"center"}
        overflow={"hidden"}
        flexDirection={"column"}
      >
        <Box
          w={"50vw"}
          minW={"xs"}
          minH={"200px"}
          marginTop={"1%"}
          bg={useColorModeValue("gray.100", "gray.900")}
          padding={"4%"}
          borderRadius={"10px"}
        >
          {isSubmitted ? (
            <Box align={"center"}>
              <CheckCircleIcon boxSize={12} color="green" />
              <br />
              <br />
              <Text fontSize={"lg"}>
                {" "}
                Your information has been saved. Thank you!{" "}
              </Text>
            </Box>
          ) : (
            <>
              <Heading overflow={"hidden"} align={"center"} margin={"10px"}>
                Parking Registration
              </Heading>
              <Text align={"center"}>
                Please fill out the form with accurate and current information.
              </Text>
              <br />
              <form onSubmit={handleSubmit}>
                <Heading as="h3" size="md" overflow={"hidden"} align={"center"}>
                  Personal Information
                </Heading>
                <br />
                <FormControl isRequired>
                  <FormLabel htmlFor="firstname">First Name</FormLabel>
                  <Input
                    id="firstname"
                    name={"firstName"}
                    placeholder="John"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="middleName">Middle Name</FormLabel>
                  <Input
                    id="middleName"
                    name={"middleName"}
                    placeholder="M."
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                  <Input
                    id="lastname"
                    name={"lastName"}
                    placeholder="Doe"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    type="email"
                    id="email"
                    name={"email"}
                    placeholder="johndoe123@email.com"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="phonenumber">Phone Number</FormLabel>
                  <Box>
                    <Flex>
                      <Select
                        backgroundColor={"white"}
                        width={"100px"}
                        name={"internationalCode"}
                        defaultValue={"+1"}
                        onChange={handleChange}
                      >
                        <option value="+1">+1 (USA)</option>
                        <option value="+52">+52 (MEX)</option>
                      </Select>
                      <Input
                        type="tel"
                        id="phonenumber"
                        name={"phoneNumber"}
                        placeholder="9151235000"
                        backgroundColor={"white"}
                        onChange={handleChange}
                      />
                    </Flex>
                  </Box>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="address">Address</FormLabel>
                  <Input
                    id="address"
                    name={"address"}
                    placeholder="501 Main St"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="unit">Unit</FormLabel>
                  <Input
                    id="unit"
                    name={"unit"}
                    placeholder="D0"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="parkingSpot">Parking Spot</FormLabel>
                  <Input
                    id="parkingSpot"
                    name={"parkingSpot"}
                    placeholder="1-23"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <br />
                <Heading as="h3" size="md" overflow={"hidden"} align={"center"}>
                  Vehicle Information
                </Heading>
                <br />
                <FormControl isRequired>
                  <FormLabel htmlFor="make">Vehicle Make </FormLabel>
                  <Input
                    type="make"
                    id="make"
                    name={"make"}
                    placeholder="Ford"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="model">Vehicle Model</FormLabel>
                  <Input
                    type="model"
                    id="model"
                    name={"model"}
                    placeholder="F-150"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="model">Vehicle Trim</FormLabel>
                  <Input
                    id="trim"
                    name={"trim"}
                    placeholder="XL 2WD Regular Cab 126"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Vehicle Color</FormLabel>
                  <Input
                    id="color"
                    name={"color"}
                    placeholder="Silver"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Vehicle Year</FormLabel>
                  <Input
                    type="year"
                    id="year"
                    name={"year"}
                    placeholder="2012"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Vehicle License Plate Number</FormLabel>
                  <Input
                    type="licensePlate"
                    id="licensePlate"
                    name={"licensePlate"}
                    placeholder="KXG1000"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="make">Vehicle VIN</FormLabel>
                  <Input
                    type="vin"
                    id="vin"
                    name={"vin"}
                    placeholder="YV1LS55A3X1588402"
                    backgroundColor={"white"}
                    onChange={handleChange}
                  />
                </FormControl>
                <br />
                <Button type={"submit"} colorScheme={"blue"} marginTop={"4%"}>
                  Register
                </Button>
              </form>
            </>
          )}
        </Box>
      </Flex>
    </div>
  );
}

export default ParkingForm;
