import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  FormControl,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Switch,
  Textarea,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import StateContext from "../StateContext";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

export default function InitialFocus(props) {
  console.log(props.contact);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputs, setInputs] = useState(props.contact);
  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const appState = useContext(StateContext);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const nameArray = name.split(".");

    if (nameArray.length === 1) {
      props.setContact((values) => ({ ...values, [name]: value }));
      setInputs((values) => ({ ...values, [name]: value }));
    } else if (nameArray.length === 2) {
      const parent = nameArray[0];
      const child = nameArray[1];
      props.setContact({ ...props.contact, [parent]: { [child]: value } });
      setInputs({ ...inputs, [parent]: { [child]: value } });
    }
  };

  async function handleEditContact(event) {
    event.preventDefault();
    const contact = props.contact;
    try {
      await axios.patch(
        baseUrl + "/update-contact",
        { contact },
        {
          headers: {
            "x-access-token": appState.user.token,
            "Content-Type": "application/json",
          },
        },
        { withCredentials: true }
      );
      props.setUpdatedContact(props.contact);
      onClose();
    } catch (e) {
      onClose();
      console.log(e);
    }
  }

  return (
    <>
      <IconButton icon={<EditIcon />} onClick={onOpen} marginLeft={"1%"} />
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Form</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel htmlFor="firstname">First Name</FormLabel>
              <Input
                id="firstname"
                name={"first_name"}
                value={props.contact.first_name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="middleName">Middle Name</FormLabel>
              <Input
                id="middleName"
                name={"middle_name"}
                value={props.contact.middle_name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="lastName">Last Name</FormLabel>
              <Input
                id="lastname"
                name={"last_name"}
                value={props.contact.last_name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                type="email"
                id="email"
                name={"email"}
                value={props.contact.email}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Client Type</FormLabel>
              <Input
                id="client_type"
                name={"client_type"}
                value={props.contact.client_type}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Occupation</FormLabel>
              <Input
                type="occupation"
                id="occupation"
                name={"occupation"}
                value={props.contact.occupation}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Company Name</FormLabel>
              <Input
                id="company_name"
                name={"company_name"}
                value={props.contact.company_name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="phonenumber">Phone Number</FormLabel>
              <Box>
                <Flex>
                  <Input
                    type="tel"
                    id="phonenumber"
                    name={"phone_number"}
                    value={props.contact.phone}
                    onChange={handleChange}
                  />
                </Flex>
              </Box>
            </FormControl>
            <Accordion allowToggle allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box textAlign="center">
                      <Heading size={"md"}>Additional Information</Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <FormControl>
                    <FormLabel htmlFor="gender">Gender</FormLabel>
                    <Input
                      type="gender"
                      id="gender"
                      name={"gender"}
                      value={props.contact.gender}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="age">Age</FormLabel>
                    <Input
                      type="number"
                      id="age"
                      name={"age"}
                      value={props.contact.gender}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="birthdate">Birthdate</FormLabel>
                    {/* <DatePicker
                selected={date}
                onChange={(date: Date) => setDate(date)}
                dateFormat={"yyyy-MM-dd"}
              /> */}
                  </FormControl>
                  <br />
                  <FormControl>
                    <FormLabel htmlFor="race">Race</FormLabel>
                    <Input
                      type="race"
                      id="race"
                      name={"race"}
                      value={props.contact.race}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="ethnicity">Ethnicity</FormLabel>
                    <Input
                      type="ethnicity"
                      id="ethnicity"
                      name={"ethnicity"}
                      value={props.contact.ethnicity}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <Heading as="h3" overflow={"hidden"} size="md">
                    Address Information
                  </Heading>
                  <FormControl>
                    <FormLabel htmlFor="street">Street</FormLabel>
                    <Input
                      type="street"
                      id="street"
                      name={"address.street"}
                      value={props.contact.address.street}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="street_opt">Street Optional</FormLabel>
                    <Input
                      type="street_opt"
                      id="street_opt"
                      name={"address.street_opt"}
                      value={props.contact.address.street_opt}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="city">City</FormLabel>
                    <Input
                      type="city"
                      id="city"
                      name={"address.city"}
                      value={props.contact.address.city}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="state">State</FormLabel>
                    <Input
                      type="state"
                      id="state"
                      name={"address.state"}
                      value={props.contact.address.state}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="country">Country</FormLabel>
                    <Input
                      type="country"
                      id="country"
                      name={"address.country"}
                      value={props.contact.address.country}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="zip">Zip</FormLabel>
                    <Input
                      type="zip"
                      id="zip"
                      name={"address.zip"}
                      value={props.contact.address.zip}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <Heading as="h3" overflow={"hidden"} size="md">
                    Social Media
                  </Heading>
                  <FormControl>
                    <FormLabel htmlFor="facebook">Facebook</FormLabel>
                    <Input
                      id="facebook"
                      name={"social_media.facebook"}
                      value={props.contact.social_media.facebook}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="instagram">Instagram</FormLabel>
                    <Input
                      id="instagram"
                      name={"social_media.instagram"}
                      value={props.contact.social_media.instagram}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="twitter">Twitter</FormLabel>
                    <Input
                      id="twitter"
                      name={"social_media.twitter"}
                      value={props.contact.social_media.twitter}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="other">Other</FormLabel>
                    <Input
                      id="other"
                      name={"social_media.Other"}
                      value={props.contact.social_media.Other}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <Heading as="h3" size="md" overflow={"hidden"}>
                    Options
                  </Heading>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="no_contact" mb="0">
                      No Contact?
                    </FormLabel>
                    <Switch
                      id="no_contact"
                      name={"no_contact"}
                      onChange={(e) => handleChange(e)}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="do_not_include" mb="0">
                      Do Not Include?
                    </FormLabel>
                    <Switch
                      id="do_not_include"
                      name={"do_not_include"}
                      onChange={(e) => handleChange(e)}
                      size="lg"
                    />
                  </FormControl>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <FormControl>
              <FormLabel>Comment:</FormLabel>
              <Textarea
                placeholder={"Start writing..."}
                name={"comment"}
                onChange={handleChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleEditContact}>
              Update
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
