import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormLabel,
  FormControl,
  Heading,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Radio,
  RadioGroup,
  Switch,
  Textarea,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import StateContext from "../StateContext";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

export default function InitialFocus(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputs, setInputs] = useState(props.commLog);
  const initialRef = React.useRef();
  const finalRef = React.useRef();
  let appState = useContext(StateContext);

  const handleChange = (event) => {
    console.log(event)
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  async function handleSubmit(event) {
    try {
      if(!appState) {
        appState = props.appState
      }

      const response = await axios.post(
        baseUrl + "/create-contact",
        {
          first_name: inputs.first_name,
          middle_name: inputs.middle_name,
          last_name: inputs.last_name,
          company_name: inputs.company_name,
          occupation: inputs.occupation,
          client_type: inputs.client_type,
          phone: props.commLog.customer_phone,
          email: inputs.email,
          address: {
            street: inputs.street,
            street_opt: inputs.street_opt,
            city: inputs.city,
            state: inputs.state,
            country: inputs.country,
            zip: inputs.zip,
          },
          gender: inputs.gender,
          age: inputs.age,
          birthdate: inputs.birthdate,
          race: inputs.race,
          ethnicity: inputs.ethnicity,
          no_contact: inputs.no_contact,
          do_not_include: inputs.do_not_include,
          social_media: {
            twitter: inputs.twitter,
            facebook: inputs.facebook,
            instagram: inputs.instagram,
            Other: inputs.Other,
          },
          additional_data: {},
          comment: inputs.comment,
          organization_id: props.commLog.organization_id,
          comm_log_id: props.commLog._id,
        },
        {
          headers: {
            "x-access-token": appState.user.token,
          },
        }
      );
      onClose();
    } catch (e) {
      onClose();
      console.log(e);
    }
  }

  return (
    <>
      <Button onClick={onOpen} colorScheme="green" marginLeft={"1%"}>
        + Add Contact
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader align={"center"}>Contact Form</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={12}>
            <Wrap>
              <WrapItem>
                <Box w={"250px"}>
                  <Heading size={"md"}>Personal Information</Heading>
                  <FormControl>
                    <FormLabel htmlFor="firstname">First Name</FormLabel>
                    <Input
                      id="firstname"
                      name={"first_name"}
                      value={inputs.first_name}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="middleName">Middle Name</FormLabel>
                    <Input
                      id="middleName"
                      name={"middle_name"}
                      value={inputs.middle_name}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="lastName">Last Name</FormLabel>
                    <Input
                      id="lastname"
                      name={"last_name"}
                      value={inputs.last_name}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      type="email"
                      id="email"
                      name={"email"}
                      value={inputs.email}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Client Type</FormLabel>
                    <Input
                      id="client_type"
                      name={"client_type"}
                      value={inputs.client_type}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Occupation</FormLabel>
                    <Input
                      type="occupation"
                      id="occupation"
                      name={"occupation"}
                      value={inputs.occupation}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Company Name</FormLabel>
                    <Input
                      id="company_name"
                      name={"company_name"}
                      value={inputs.company_name}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="phonenumber">Phone Number</FormLabel>
                    <Box>
                      <Flex>
                        <Input
                          type="tel"
                          id="phonenumber"
                          name={"phone_number"}
                          value={props.commLog.customer_phone}
                          onChange={handleChange}
                        />
                      </Flex>
                    </Box>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="gender">Gender</FormLabel>
                    <Input
                      type="gender"
                      id="gender"
                      name={"gender"}
                      value={inputs.gender}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="age">Age</FormLabel>
                    <Input
                      type="number"
                      id="age"
                      name={"age"}
                      value={inputs.age}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="birthdate">Birthdate</FormLabel>
                    {/* <DatePicker
                selected={date}
                onChange={(date: Date) => setDate(date)}
                dateFormat={"yyyy-MM-dd"}
              /> */}
                  </FormControl>
                  <br />
                  <FormControl>
                    <FormLabel htmlFor="race">Race</FormLabel>
                    <Input
                      type="race"
                      id="race"
                      name={"race"}
                      value={inputs.race}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="ethnicity">Ethnicity</FormLabel>
                    <Input
                      type="ethnicity"
                      id="ethnicity"
                      name={"ethnicity"}
                      value={inputs.ethnicity}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <Heading as="h3" overflow={"hidden"} size="md">
                    Address Information
                  </Heading>
                  <FormControl>
                    <FormLabel htmlFor="street">Street</FormLabel>
                    <Input
                      type="street"
                      id="street"
                      name={"street"}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="street_opt">Street Optional</FormLabel>
                    <Input
                      type="street_opt"
                      id="street_opt"
                      name={"street_opt"}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="city">City</FormLabel>
                    <Input
                      type="city"
                      id="city"
                      name={"city"}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="state">State</FormLabel>
                    <Input
                      type="state"
                      id="state"
                      name={"state"}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="country">Country</FormLabel>
                    <Input
                      type="country"
                      id="country"
                      name={"country"}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="zip">Zip</FormLabel>
                    <Input
                      type="zip"
                      id="zip"
                      name={"zip"}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <Heading as="h3" overflow={"hidden"} size="md">
                    Social Media
                  </Heading>
                  <FormControl>
                    <FormLabel htmlFor="facebook">Facebook</FormLabel>
                    <Input
                      id="facebook"
                      name={"facebook"}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="instagram">Instagram</FormLabel>
                    <Input
                      id="instagram"
                      name={"instagram"}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="twitter">Twitter</FormLabel>
                    <Input
                      id="twitter"
                      name={"twitter"}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="other">Other</FormLabel>
                    <Input id="other" name={"Other"} onChange={handleChange} />
                  </FormControl>
                  <Heading as="h3" size="md" overflow={"hidden"}>
                    Options
                  </Heading>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="no_contact" mb="0">
                      No Contact?
                    </FormLabel>
                    <Switch
                      id="no_contact"
                      name={"no_contact"}
                      onChange={(e) => handleChange(e)}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="do_not_include" mb="0">
                      Do Not Include?
                    </FormLabel>
                    <Switch
                      id="do_not_include"
                      name={"do_not_include"}
                      onChange={(e) => handleChange(e)}
                      size="lg"
                    />
                  </FormControl>
                </Box>
              </WrapItem>
              <WrapItem>
                <Box w={"250px"}>
                  <Heading size={"md"}>Questionnaire</Heading>
                  <FormControl>
                    <FormLabel htmlFor="question1">
                      Reason(s) for moving in:
                    </FormLabel>
                    <CheckboxGroup
                      colorScheme="green"
                      name={"question1"}
                      onChange={handleChange}
                    >
                      <VStack align="left">
                        <Checkbox>Relocate to a better home</Checkbox>
                        <Checkbox>Cheaper housing</Checkbox>
                        <Checkbox>Closer to work or easier commute</Checkbox>
                        <Checkbox>Change in marital status</Checkbox>
                        <Checkbox>A safer neighborhood</Checkbox>
                        <Checkbox>Other reason(s)</Checkbox>
                      </VStack>
                    </CheckboxGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="question2">Currently living:</FormLabel>
                    <CheckboxGroup
                      colorScheme="green"
                      name={"question2"}
                      onChange={handleChange}
                    >
                      <VStack align="left">
                        <Checkbox>East side</Checkbox>
                        <Checkbox>Far East</Checkbox>
                        <Checkbox>West side</Checkbox>
                        <Checkbox>Northeast</Checkbox>
                        <Checkbox>Downtown</Checkbox>
                        <Checkbox>Central</Checkbox>
                        <Checkbox>Outside of town</Checkbox>
                      </VStack>
                    </CheckboxGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="question3">Age:</FormLabel>
                    <CheckboxGroup
                      colorScheme="green"
                      name={"question3"}
                      onChange={handleChange}
                    >
                      <VStack align="left">
                        <Checkbox>Under 18 (Cannot lease)</Checkbox>
                        <Checkbox>18 - 24</Checkbox>
                        <Checkbox>25 - 30</Checkbox>
                        <Checkbox>31 - 40</Checkbox>
                        <Checkbox>41 - 50</Checkbox>
                        <Checkbox>51 - 64</Checkbox>
                        <Checkbox>65+</Checkbox>
                      </VStack>
                    </CheckboxGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="question5">
                      Dislikes about current apartment/home:
                    </FormLabel>
                    <CheckboxGroup
                      colorScheme="green"
                      name={"question5"}
                      onChange={handleChange}
                    >
                      <VStack align="left">
                        <Checkbox>Deposit refund disputes</Checkbox>
                        <Checkbox>Limited parking</Checkbox>
                        <Checkbox>Bed bug infestations</Checkbox>
                        <Checkbox>Repair problems</Checkbox>
                        <Checkbox>Apartment noise complaints</Checkbox>
                        <Checkbox>
                          Health hazards / Safety related problems
                        </Checkbox>
                        <Checkbox>Breach of Agreement</Checkbox>
                        <Checkbox>Eviction Complaints</Checkbox>
                        <Checkbox>Lease Terminations</Checkbox>
                        <Checkbox>Other</Checkbox>
                      </VStack>
                    </CheckboxGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="question6">
                      Desired move-in date:
                    </FormLabel>
                    <p>Insert date picker here</p>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="question7">Occupation:</FormLabel>
                    <CheckboxGroup
                      colorScheme="green"
                      name={"question7"}
                      onChange={handleChange}
                    >
                      <VStack align="left">
                        <Checkbox>Architecture and construction</Checkbox>
                        <Checkbox>Technology</Checkbox>
                        <Checkbox>Business and finance</Checkbox>
                        <Checkbox>
                          Government and public administration
                        </Checkbox>
                        <Checkbox>Education and training</Checkbox>
                        <Checkbox>Health science</Checkbox>
                        <Checkbox>Information technology</Checkbox>
                        <Checkbox>
                          Science, Technology, Engineering, and Math (STEM)
                        </Checkbox>
                        <Checkbox>Marketing</Checkbox>
                        <Checkbox>Other</Checkbox>
                      </VStack>
                    </CheckboxGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="question8">Occupation:</FormLabel>
                    <Input
                      id="question8"
                      name={"question8"}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="question9">Pet Owner?:</FormLabel>
                    <RadioGroup
                      colorScheme="green"
                      name={"question9"}
                      onChange={handleChange}
                    >
                      <VStack align="left">
                        <Radio>Yes</Radio>
                        <Radio>No</Radio>
                      </VStack>
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="question10">Desired Bedroom Count:</FormLabel>
                    <CheckboxGroup
                        colorScheme="green"
                        name={"question10"}
                        onChange={handleChange}
                    >
                      <VStack align="left">
                        <Checkbox>1</Checkbox>
                        <Checkbox>2</Checkbox>
                        <Checkbox>3</Checkbox>
                        <Checkbox>4+</Checkbox>
                      </VStack>
                    </CheckboxGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="question10">Desired Bedroom Count:</FormLabel>
                    <CheckboxGroup
                        colorScheme="green"
                        name={"question10"}
                        onChange={handleChange}
                    >
                      <VStack align="left">
                        <Checkbox>1</Checkbox>
                        <Checkbox>2</Checkbox>
                        <Checkbox>3</Checkbox>
                        <Checkbox>4+</Checkbox>
                      </VStack>
                    </CheckboxGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="question11">Preferred price range:</FormLabel>
                    <CheckboxGroup
                        colorScheme="green"
                        name={"question11"}
                        onChange={handleChange}
                    >
                      <VStack align="left">
                        <Checkbox>$500 - $749</Checkbox>
                        <Checkbox>$750 - $999</Checkbox>
                        <Checkbox>$1,000 - $1,249</Checkbox>
                        <Checkbox>$1,250 - $1,499</Checkbox>
                        <Checkbox>$1,500+</Checkbox>
                        <Checkbox>Other</Checkbox>
                      </VStack>
                    </CheckboxGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="question12">Planning to live at the apartment:</FormLabel>
                    <CheckboxGroup
                        colorScheme="green"
                        name={"question12"}
                        onChange={handleChange}
                    >
                      <VStack align="left">
                        <Checkbox>Short-term (1 mo - 6 mos)</Checkbox>
                        <Checkbox>Long-term (7+ mos)</Checkbox>
                        <Checkbox>$1,000 - $1,249</Checkbox>
                        <Checkbox>$1,250 - $1,499</Checkbox>
                        <Checkbox>$1,500+</Checkbox>
                        <Checkbox>Unsure</Checkbox>
                      </VStack>
                    </CheckboxGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="question13">Hobbies:</FormLabel>
                    <CheckboxGroup
                        colorScheme="green"
                        name={"question13"}
                        onChange={handleChange}
                    >
                      <VStack align="left">
                        <Checkbox>Entertainment (Video games, movies, etc.)</Checkbox>
                        <Checkbox>Active lifestyle (Working out, sports, park, etc.)</Checkbox>
                        <Checkbox>Noisy prominent activities (DIY, instruments, partying, etc.)</Checkbox>
                        <Checkbox>Other</Checkbox>
                      </VStack>
                    </CheckboxGroup>
                  </FormControl>
                </Box>
              </WrapItem>
              <WrapItem>
                <FormControl>
                  <FormLabel>Comment:</FormLabel>
                  <Textarea
                    placeholder={"Start writing..."}
                    name={"comment"}
                    onChange={handleChange}
                  />
                </FormControl>
              </WrapItem>
            </Wrap>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
