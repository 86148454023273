import React, { useContext} from "react";
import axios from 'axios'
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, useColorModeValue, Text } from "@chakra-ui/react";
import { HamburgerIcon } from '@chakra-ui/icons'
import { AppContext } from '../Context'
import logo from "../img/im-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import MenuBar from "./MenuBar";

export default function Navbar() {
  axios.defaults.withCredentials = true
  const appDispatch = useContext(AppContext)
  let navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_BASEURL

  async function handleLogout() {
    try {
      await axios.get(baseUrl + '/logout', {}, { withCredentials: true })


      appDispatch({ type: 'logout' })
      navigate('/')
    } catch (e) {
      console.log('There was a problem')
    }
  }

  return (
    <div>
      <Box
        overflow-y={"hidden"}
        bg={useColorModeValue("gray.100", "gray.900")}
        px={2}
      >
        <Flex
          h={20}
          alignItems={"center"}
          justifyContent={"space-between"}
          overflow={"hidden"}
        >
          <Flex spacing={2} alignItems={"center"} flexDirection={"row"}>
            <Box>
              <Link
                to={"/dashboard"}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <Flex alignItems={"center"}>
                  <Box marginRight={"1em"}>
                    <img
                      className="im-logo-header"
                      src={logo}
                      alt={"IM LOGO"}
                    />
                  </Box>

                  <Text
                    fontSize={"lg"}
                    fontWeight={"bold"}
                    fontSize={{ base: "0px", md: "16px", lg: "20px" }}
                  >
                    Leasing Center
                  </Text>
                </Flex>
              </Link>
            </Box>
          </Flex>
          <Menu>
            <MenuButton
                as={Button}
                fontSize={{ base: '12px', md: '14px', lg: '18px' }}
                mr={'4'}
                rightIcon={<HamburgerIcon />}
            ></MenuButton>
            <MenuList>
              <MenuItem fontSize={{ base: '12px', md: '14px', lg: '18px' }}>
                My Profile
              </MenuItem>
              <MenuItem fontSize={{ base: '12px', md: '14px', lg: '18px' }}>
                Settings
              </MenuItem>
              <MenuItem onClick={handleLogout} colorScheme="green" fontSize={{ base: '12px', md: '14px', lg: '18px' }}>
                Sign Out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <MenuBar />
      </Box>
    </div>
  );
}

