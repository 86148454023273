import React, { useContext, useEffect, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Select,
  Text,
  Textarea,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import StateContext from "../StateContext";
import ContactEditFormModal from "./ContactEditFormModal";
import axios from "axios";
import ActivityLog from "./ActivityLog";
const baseUrl = process.env.REACT_APP_BASEURL;

export default function ContactInfoBox(props) {
  const [inputs, setInputs] = useState({});
  const [sort, setSort] = useState("0");
  const [contact, setContact] = useState({
    address: {},
    social_media: {},
    comments: [],
  });
  const [editContact, setEditContact] = useState({
    address: {},
    social_media: {},
    comments: [],
  });
  const [address, setAddress] = useState({});
  const [comments, setComments] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();
  const appState = useContext(StateContext);

  useEffect(() => {
    async function fetchContact() {
      try {
        if (!props.contact) {
          const contactResponse = await axios.post(
            baseUrl + "/get-contact-by-id",
            {
              contact_id: props.commLog.contact_id,
            },
            {
              headers: {
                "x-access-token": appState.user.token,
                "Content-Type": "application/json",
              },
            },
            { withCredentials: true }
          );

          console.log(contactResponse.data.comment)
          setContact(contactResponse.data);
          setEditContact(contactResponse.data); //For the edit contact modal
          setAddress(contactResponse.data.address);
          setComments(contactResponse.data.comment);
        } else {
          console.log(props.contact.comment)
          setContact(props.contact);
          setComments(props.contact.comment);
          setAddress(props.contact.address);
          setEditContact(props.contact); //For the edit contact modal
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchContact();
  }, []);

  async function handleDeleteComment(event) {
    console.log(event);
    const comment_id = event;
    console.log(comment_id);
    try {
      const commentResponse = await axios.post(
        baseUrl + "/delete-comment-from-contact",
        {
          comment_id: comment_id,
          contact_id: contact._id,
        },
        {
          headers: {
            "x-access-token": appState.user.token,
            "Content-Type": "application/json",
          },
        },
        { withCredentials: true }
      );
      contact.comment = commentResponse.data.comment;
      setContact(contact);
      setComments(commentResponse.data.comment);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDeleteContact(event) {
    event.preventDefault();
    try {
      let contact_id = "";
      if (props.commLog) {
        contact_id = props.commLog.contact_id;
      } else {
        contact_id = props.contact._id;
      }

      await axios.delete(
        baseUrl + "/delete-contact",
        {
          headers: {
            "x-access-token": appState.user.token,
            contact_id: contact_id,
          },
        },
        { withCredentials: true }
      );

      setContact({
        address: {},
        social_media: {},
        comments: [],
      });
      setEditContact({
        address: {},
        social_media: {},
        comments: [],
      });
      setAddress({});
      setComments([]);
      if (props.commLog) {
        props.commLog.contact_id = null;
      }
      onClose();
    } catch (e) {
      console.log(e);
    }
  }

  async function handleCommentSubmit(event) {
    try {
      let contact_id = "";
      if (props.commLog) {
        contact_id = props.commLog.contact_id;
      } else {
        contact_id = props.contact._id;
      }
      const commentResponse = await axios.post(
        baseUrl + "/add-comment-to-contact",
        {
          contact_id: contact_id,
          content: inputs.comment,
        },
        {
          headers: {
            "x-access-token": appState.user.token,
            "Content-Type": "application/json",
          },
        },
        { withCredentials: true }
      );

      contact.comment = commentResponse.data.comment;
      setContact(contact);
      setComments(commentResponse.data.comment);
    } catch (e) {
      console.log(e);
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSortChange = (event) => {
    console.log(event.target.value);
    setSort(event.target.value);
  };

  return (
    <Wrap>
      <WrapItem>
        <Box
          padding="2%"
          bg="whitesmoke"
          borderRadius={"4px"}
          boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
          w={"300px"}
        >
          <HStack>
            <Avatar />
            <Text fontSize="lg">
              {contact.first_name} {contact.middle_name} {contact.last_name}
            </Text>
            <ContactEditFormModal
              contact={editContact}
              setContact={setEditContact}
              setUpdatedContact={setContact}
            />

            <IconButton
              icon={<DeleteIcon />}
              colorScheme="red"
              onClick={() => setIsOpen(true)}
            ></IconButton>
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete Contact
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={handleDeleteContact}
                      ml={3}
                    >
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </HStack>
          <Heading size={"md"}>Personal Information</Heading>
          <HStack>
            <Text>Phone: </Text>
            <Text color={"blue"}>{contact.phone}</Text>
          </HStack>
          <HStack>
            <Text>Email: </Text>
            <Text noOfLines={[1, 2, 3]} color={"blue"}>
              {contact.email}
            </Text>
          </HStack>
          <HStack>
            <Text>Client Type: </Text>
            <Text color={"blue"}>{contact.client_type}</Text>
          </HStack>
          <HStack>
            <Text>Company Name: {contact.company_name}</Text>
            <Text color={"blue"}>{contact.company_name}</Text>
          </HStack>
          <HStack>
            <Text>Occupation: </Text>
            <Text color={"blue"}>{contact.occupation}</Text>
          </HStack>
          <HStack>
            <Text>Gender: </Text>
            <Text color={"blue"}>{address.gender}</Text>
          </HStack>
          <HStack>
            <Text>Age: </Text>
            <Text color={"blue"}>{address.age}</Text>
          </HStack>
          <HStack>
            <Text>Race: </Text>
            <Text color={"blue"}>{address.race}</Text>
          </HStack>
          <HStack>
            <Text>Ethnicity: </Text>
            <Text color={"blue"}>{address.ethnicity}</Text>
          </HStack>
          <Heading size={"md"}>Address</Heading>
          <HStack>
            <Text>Street: </Text>
            <Text color={"blue"}>{address.street}</Text>
          </HStack>
          <HStack>
            <Text>Street Opt: </Text>
            <Text color={"blue"}>{address.street_opt}</Text>
          </HStack>
          <HStack>
            <Text>City: </Text>
            <Text color={"blue"}>{address.city}</Text>
          </HStack>
          <HStack>
            <Text>State: </Text>
            <Text color={"blue"}>{address.state}</Text>
          </HStack>
          <HStack>
            <Text>Country: </Text>
            <Text color={"blue"}>{address.country}</Text>
          </HStack>
          <HStack>
            <Text>Zip: </Text>
            <Text color={"blue"}>{address.zip}</Text>
          </HStack>
        </Box>
      </WrapItem>
      <WrapItem>
        {props.commLog ? (
          <ActivityLog phone={props.commLog.customer_phone} />
        ) : (
          <ActivityLog phone={props.contact.phone} />
        )}
      </WrapItem>
      <WrapItem>
        <Box
          padding="2%"
          margin={"1%"}
          bg="whitesmoke"
          borderRadius={"4px"}
          boxShadow={"0px 5px 15px rgba(0, 0, 0, 0.35)"}
          w={"300px"}
        >
          <Flex justifyContent={"space-between"}>
            <Select
              width={"300px"}
              id={"sort-selector"}
              value={sort}
              onChange={handleSortChange}
              label={"sort by"}
            >
              <option value={"0"}> Newest Comments </option>
              <option value={"1"}> Oldest Comments </option>
            </Select>
          </Flex>
          {comments.length > 0 ? (
            <Box overflow={"scroll"}>
              {/*Comments will be displayed here*/}
              {sort === "0" ? (
                <div>
                  {comments
                    .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
                    .map((comment) => {
                      return (
                        <>
                          <Box
                            bg={"#E8E8E8"}
                            borderRadius={"8px"}
                            padding={"2%"}
                            key={comment._id}
                          >
                            <HStack>
                              <Text>Agent ID: {comment.agent_id}</Text>
                              <IconButton
                                colorScheme={"red"}
                                value={comment._id}
                                onClick={(e) =>
                                  handleDeleteComment(comment._id)
                                }
                                icon={<DeleteIcon />}
                                size={"sm"}
                              ></IconButton>
                            </HStack>
                            <Text>{comment.content}</Text>
                            <Text>
                              <b>
                                {new Date(comment.timestamp).toLocaleString()}
                              </b>
                            </Text>
                          </Box>
                          <br />
                        </>
                      );
                    })}
                </div>
              ) : (
                <>
                  {comments
                    .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
                    .map((comment) => {
                      return (
                        <>
                          <Box
                            bg={"#E8E8E8"}
                            borderRadius={"8px"}
                            padding={"2%"}
                            key={comment._id}
                          >
                            <HStack>
                              <Text>Agent ID: {comment.agent_id}</Text>
                              <IconButton
                                colorScheme={"red"}
                                value={comment._id}
                                onClick={handleDeleteComment}
                                icon={<DeleteIcon />}
                                size={"sm"}
                              ></IconButton>
                            </HStack>
                            <Text>{comment.content}</Text>
                            <Text>
                              <b>
                                {new Date(comment.timestamp).toLocaleString()}
                              </b>
                            </Text>
                          </Box>
                          <br />
                        </>
                      );
                    })}
                </>
              )}
            </Box>
          ) : (
            <p>No Comments Added Yet</p>
          )}
          <br />
          <FormControl isRequired>
            <FormLabel htmlFor="comment">Comment</FormLabel>
            <Textarea
              isRequired
              id="comment"
              placeholder={"Start writing..."}
              name={"comment"}
              onChange={handleChange}
            />
            <br />
            <Button colorScheme={"green"} onClick={handleCommentSubmit}>
              + Add Comment
            </Button>
          </FormControl>
        </Box>
      </WrapItem>
    </Wrap>
  );
}
